import { Card, Col, Dropdown, Row } from 'react-bootstrap'
import PropTypes from 'prop-types'
import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'

import formatDurationToTimestamp from '../util/formatDurationToTimestamp.js'
import youtubeThumbnail from '../util/youtubeThumbnail'

function VideoClip(props) {
    const location = useLocation()

    const tags = props.clip.tags
    const formattedTags = tags.map((tag) => `#${tag}`).join(' ')

    let videoId = props.videoId
    let sourceUrl = null
    let thumbnail = null
    if (props.clip.video) {
        videoId = props.clip.video.id
        sourceUrl = props.clip.video.sourceUrl
        thumbnail = youtubeThumbnail(props.clip.video.sourceUrl).high
    }

    const handleClipClick = () => {
        if (props.clipSelectCallback) {
            props.clipSelectCallback(props.clip)
        }
    }
    const handleDropdownClick = (e) => {
        e.stopPropagation()
    }

    const handleEditClick = () => {
        if (props.clipEditCallback) {
            props.clipEditCallback(props.clip)
        }
    }

    const deleteClip = async function () {
        try {
            await props.apiClient.delete(`videos/clips/${props.clip.id}`)
        } catch (err) {
            if (!(await props.apiClient.displayErrorToast(err))) {
                throw err
            }
        }
        await props.deleteClipCallback()
    }

    async function deleteClipbookClip() {
        try {
            await props.apiClient.delete(`clipbooks/clips/${props.clip.id}`)
        } catch (err) {
            if (!(await props.apiClient.displayErrorToast(err))) {
                throw err
            }
        }
        await props.deleteClipCallback()
    }

    function generateCopyLinkUrl() {
        if (!location.pathname.includes('/clipbook/')) {
            return (
                window.location.protocol +
                '//' +
                window.location.host +
                '/watch/' +
                videoId +
                '?clip=' +
                props.clip.id
            )
        } else if (location.pathname.includes('/clipbook/')) {
            return (
                window.location.protocol +
                '//' +
                window.location.host +
                '/clipbook/' +
                videoId +
                '?clip=' +
                props.clip.id
            )
        }
    }

    const copyLinkToast = () => {
        toast.success('Link copied to clipboard', {
            autoClose: 3000,
        })
    }

    return (
        <Card
            bg="light"
            onClick={handleClipClick}
            className="videoClip"
            style={{ border: '0' }}
        >
            <div className="dropdownDiv">
                <Dropdown onClick={handleDropdownClick}>
                    <Dropdown.Toggle
                        variant="outline-warning"
                        className="videoClipDropdown"
                        size="sm"
                    />
                    <Dropdown.Menu>
                        {props.viewerStatus === 'owner' &&
                        !location.pathname.includes('/clipbook/') ? (
                            <div>
                                <CopyToClipboard text={generateCopyLinkUrl()}>
                                    <Dropdown.Item onClick={copyLinkToast}>
                                        Copy Link
                                    </Dropdown.Item>
                                </CopyToClipboard>
                                <Dropdown.Item
                                    onClick={() =>
                                        props.addToClipbookModalCallback(
                                            props.clip.id
                                        )
                                    }
                                >
                                    Add to Clipbook
                                </Dropdown.Item>
                            </div>
                        ) : (
                            <div />
                        )}
                        {props.viewerStatus === 'owner' &&
                        location.pathname.includes('/watch/') ? (
                            <div>
                                <Dropdown.Item onClick={handleEditClick}>
                                    Edit
                                </Dropdown.Item>
                                <Dropdown.Item onClick={deleteClip}>
                                    Delete
                                </Dropdown.Item>
                            </div>
                        ) : (
                            <div />
                        )}
                        {props.viewerStatus === 'owner' &&
                        location.pathname.includes('/clipbook/') ? (
                            <div>
                                <Dropdown.Item onClick={deleteClipbookClip}>
                                    Delete
                                </Dropdown.Item>
                            </div>
                        ) : (
                            <div />
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <Row className="videoClipMagic" noGutters>
                {sourceUrl ? (
                    <div className="videoInfoColumn">
                        <Card.Header>
                            <Row noGutters className="videoClipSourceInfo">
                                <div className="thumbnailSection">
                                    <div className="videoClipImageDiv">
                                        <Card.Img
                                            variant="top"
                                            className="videoClipImage"
                                            src={thumbnail}
                                        />
                                    </div>
                                </div>
                                <Col className="titleSection">
                                    <div className="videoClipTitle text-muted">
                                        {props.clip.video.title}
                                    </div>
                                </Col>
                            </Row>
                        </Card.Header>
                    </div>
                ) : (
                    <div />
                )}
                <div className="videoClipColumn">
                    <Card.Body className="videoClipCardBody">
                        <Card.Subtitle>
                            <Row>
                                <Col
                                    xl={9}
                                    lg={9}
                                    md={9}
                                    sm={9}
                                    className="videoClipTimestamp"
                                >
                                    {formatDurationToTimestamp({
                                        milliseconds: props.clip.startAtMs,
                                    })}
                                    {' - '}
                                    {formatDurationToTimestamp({
                                        milliseconds: props.clip.endAtMs,
                                    })}
                                </Col>
                            </Row>
                        </Card.Subtitle>
                        <Card.Text>{props.clip.thread.content}</Card.Text>
                        <small className="text-muted">{formattedTags}</small>
                    </Card.Body>
                </div>
            </Row>
        </Card>
    )
}

VideoClip.propTypes = {
    clip: PropTypes.object.isRequired,
    clipSelectCallback: PropTypes.func,
    apiClient: PropTypes.object.isRequired,
    deleteClipCallback: PropTypes.func,
    viewerStatus: PropTypes.string,
    clipEditCallback: PropTypes.func,
    videoId: PropTypes.string,
    addToClipbookModalCallback: PropTypes.func,
}

export default VideoClip
