import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Container, Row, Col } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'
import { intersection } from 'lodash'
import ky from 'ky'

import AddToClipbookModal from './AddToClipbookModal'
import AutoPlayControl from './AutoPlayControl'
import SearchClips from './SearchClips'
import TagList from './TagList'
import ClipList from './ClipList'
import VideoPlayer from './VideoPlayer'
import VideoClip from './VideoClip'
import ClipCreationForm from './ClipCreationForm'
import ClipLoopControl from './ClipLoopControl'
import ClipSpeedControl from './ClipSpeedControl'

function VideoViewer(props) {
    const [viewerMode, setViewerMode] = useState(null)
    const [focusedVideo, setFocusedVideo] = useState(null)
    const [focusedClip, setFocusedClip] = useState(null)
    const [focusedClipIndex, setFocusedClipIndex] = useState(null)
    const [selectedTags, setSelectedTags] = useState([])
    const [isLooping, setIsLooping] = useState(true)
    const [isAutoPlay, setIsAutoPlay] = useState(false)
    const [playbackSpeed, setPlaybackSpeed] = useState(null)
    const [isCreatingClip, setIsCreatingClip] = useState(false)
    const [playbackStartAtMs, setPlaybackStartAtMs] = useState(0)
    const [playbackEndAtMs, setPlaybackEndAtMs] = useState(Infinity)
    const [searchText, setSearchText] = useState(null)
    const [viewerStatus, setViewerStatus] = useState(viewerStatus)
    const [editedClip, setEditedClip] = useState(null)
    const [clips, setClips] = useState([])
    const [show, setShow] = useState(false)
    const [selectedVideoClipId, setSelectedVideoClipId] = useState(false)

    const videoRef = useRef()
    const clipbookRef = useRef()

    useEffect(() => {
        checkViewerMode()
        async function makeApiCalls() {
            let video

            if (props.location.pathname.includes('watch')) {
                video = await refreshVideo()
                updateFocusedVideo()
            } else if (props.location.pathname.includes('clipbook')) {
                await refreshClipbook()
                updateFocusedVideo()
            }

            getRelevantClips()

            if (video && video.clips.length > 0 && props.location.search) {
                const clip_url_id = props.location.search.slice(
                    props.location.search.search('=') + 1
                )

                const matchedClipFromUrl = video.clips.filter(
                    (clip) => clip.id == clip_url_id
                )

                if (matchedClipFromUrl.length > 0) {
                    updateFocusedClip(matchedClipFromUrl[0])
                }
            }
            await calculateViewerStatus()
        }
        makeApiCalls()

        return () => {}
    }, [viewerMode])

    // updates clip list after tags, search
    // or clip edition
    useEffect(() => {
        getRelevantClips()
    }, [selectedTags, searchText, editedClip])

    function checkViewerMode() {
        setViewerMode(
            props.location.pathname.includes('watch') ? 'video' : 'clipbook'
        )
    }

    async function refreshVideo() {
        try {
            const video = await props.apiClient.get(
                `videos/${props.match.params.video_url_id}`
            )
            videoRef.current = video
            return video
        } catch (err) {
            if (!(await props.apiClient.displayErrorToast(err))) {
                throw err
            }
        }
    }

    async function refreshClipbook() {
        try {
            const clipbook = await props.apiClient.get(
                `clipbooks/${props.match.params.clipbook_id}`
            )
            clipbookRef.current = clipbook
            return clipbook
        } catch (err) {
            if (!(await props.apiClient.displayErrorToast(err))) {
                throw err
            }
        }
    }

    function updateFocusedVideo(video) {
        if (videoRef.current) {
            setFocusedVideo(videoRef.current)
        } else if (!focusedVideo && clipbookRef.current.clips.length === 0) {
            setFocusedVideo(null)
        } else if (!focusedVideo && clipbookRef.current.clips.length > 0) {
            setFocusedVideo(clipbookRef.current.clips[0].videoClip.video)
        } else {
            setFocusedVideo(video)
        }
    }

    async function calculateViewerStatus() {
        try {
            const user = await props.apiClient.get('users/me')

            if (
                viewerMode === 'video' &&
                user.id === videoRef.current.user.id
            ) {
                setViewerStatus('owner')
            } else if (
                viewerMode === 'clipbook' &&
                user.id === clipbookRef.current.user.id
            ) {
                setViewerStatus('owner')
            } else {
                setViewerStatus('user')
            }
        } catch (err) {
            if (err instanceof ky.HTTPError && err.response.status === 401) {
                setViewerStatus('unknown')
                return false
            }

            throw err
        }
    }

    async function deleteClipCallback() {
        if (viewerMode === 'video') {
            const video = await refreshVideo()
            setClips(video.clips)
        } else if (viewerMode === 'clipbook') {
            await refreshClipbook()
            getRelevantClips()
        }
        exitClipFocusView()
    }

    function updateFocusedClip(clip) {
        setPlaybackStartAtMs(clip.startAtMs)
        setPlaybackEndAtMs(clip.endAtMs)
        setEditedClip(null)
        setPlaybackSpeed(clip.defaultPlaybackSpeed)
        if (isAutoPlay === false) {
            setIsLooping(clip.shouldLoopByDefault)
        }
        setFocusedClipIndex(
            clips.findIndex((videoClip) => videoClip.id === clip.id)
        )

        if (viewerMode === 'clipbook') {
            setFocusedVideo(clip.video)
        }
        setFocusedClip(clip)

        ReactGA.event({
            category: 'User',
            action: 'Enter clip focus view',
        })
    }

    function clearClipUrl() {
        if (viewerMode === 'video') {
            history.replaceState(null, 'none', '/watch/' + videoRef.current.id)
        } else if (viewerMode === 'clipbook') {
            history.replaceState(
                null,
                'none',
                '/clipbook/' + clipbookRef.current.id
            )
        } else {
            return null
        }
    }

    function exitClipFocusView() {
        setFocusedClip(null)
        setPlaybackSpeed(1)
        setIsLooping(true)
        setFocusedClipIndex(null)
        setPlaybackStartAtMs(0)
        setPlaybackEndAtMs(Infinity)
        setIsAutoPlay(false)

        ReactGA.event({
            category: 'User',
            action: 'Exited clip focus view',
        })

        clearClipUrl()
    }

    function nextFocusedClip() {
        ReactGA.event({
            category: 'User',
            action: 'Clicked next clip',
        })

        clearClipUrl()

        if (clips[focusedClipIndex + 1]) {
            updateFocusedClip(clips[focusedClipIndex + 1])
            return true
        } else {
            return false
        }
    }

    function previousFocusedClip() {
        ReactGA.event({
            category: 'User',
            action: 'Clicked previous clip',
        })

        clearClipUrl()

        if (clips[focusedClipIndex - 1]) {
            updateFocusedClip(clips[focusedClipIndex - 1])
            return true
        } else {
            return false
        }
    }

    function updateSelectedTags(clickedTag) {
        if (selectedTags.includes(clickedTag)) {
            setSelectedTags(selectedTags.filter((tag) => tag !== clickedTag))
        } else {
            setSelectedTags(selectedTags.concat([clickedTag]))
        }
    }

    function clearSelectedTags() {
        setSelectedTags([])
    }

    function updatePlaybackSpeed(selectedSpeed) {
        if (playbackSpeed !== selectedSpeed) {
            setPlaybackSpeed(selectedSpeed)
        }
    }

    function updatePlaybackStartAndEnd({ startAtMs, endAtMs }) {
        setPlaybackStartAtMs(startAtMs)
        setPlaybackEndAtMs(endAtMs)
    }

    function toggleLoopingState() {
        if (isLooping === false) {
            setIsLooping(true)
            setIsAutoPlay(false)
        } else {
            setIsLooping(false)
        }
    }

    function toggleAutoPlay() {
        if (isAutoPlay === false) {
            setIsAutoPlay(true)
            setIsLooping(false)
        } else {
            setIsAutoPlay(false)
            setIsLooping(focusedClip.shouldLoopByDefault)
        }
    }

    function getRelevantClips() {
        const selectedTagsFilterFunction =
            selectedTags.length > 0
                ? (clip) =>
                      intersection(clip.tags, selectedTags).length ===
                      selectedTags.length
                : (clip) => true
        const searchTextFilterFunction = searchText
            ? (clip) =>
                  clip.thread.content
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
            : (clip) => true

        if (viewerMode === 'video') {
            const { current = {} } = videoRef
            const { clips = [] } = current
            setClips(
                clips
                    .filter(selectedTagsFilterFunction)
                    .filter(searchTextFilterFunction)
            )
        } else if (viewerMode === 'clipbook') {
            const { current = {} } = clipbookRef
            const { clips = [] } = current

            let flattenedClipbookClipArray = []

            clips.forEach((clipbookClip) => {
                const flattenedClip = {
                    id: clipbookClip.id,
                    videoClipId: clipbookClip.videoClipId,
                    thread: clipbookClip.videoClip.thread,
                    startAtMs: clipbookClip.videoClip.startAtMs,
                    endAtMs: clipbookClip.videoClip.endAtMs,
                    tags: clipbookClip.videoClip.tags,
                    defaultPlaybackSpeed:
                        clipbookClip.videoClip.defaultPlaybackSpeed,
                    shouldLoopByDefault:
                        clipbookClip.videoClip.shouldLoopByDefault,
                    order: clipbookClip.order,
                    video: clipbookClip.videoClip.video,
                }
                flattenedClipbookClipArray.push(flattenedClip)
            })
            setClips(
                flattenedClipbookClipArray
                    .filter(selectedTagsFilterFunction)
                    .filter(searchTextFilterFunction)
            )
        } else {
            return null
        }
    }

    function handleSearchChange(e) {
        setSearchText(e.target.value)
    }

    function enableSortableClipList() {
        if (!searchText && selectedTags.length === 0) {
            return true
        } else {
            return false
        }
    }

    async function createClip({ comment, tags }) {
        try {
            await props.apiClient.post(`videos/${videoRef.current.id}/clips`, {
                comment,
                startAtMs: Math.round(playbackStartAtMs),
                endAtMs: Math.round(playbackEndAtMs),
                tags,
                defaultPlaybackSpeed: playbackSpeed,
                shouldLoopByDefault: isLooping,
            })

            const video = await refreshVideo()
            setClips(video.clips)
        } catch (err) {
            if (!(await props.apiClient.displayErrorToast(err))) {
                throw err
            }
        }

        setIsCreatingClip(false)
        setPlaybackSpeed(1)
        setPlaybackStartAtMs(0)
        setPlaybackEndAtMs(Infinity)
    }

    function enterCreateClipView() {
        setFocusedClip(null)
        setIsLooping(true)
        setPlaybackSpeed(1)
        setIsCreatingClip(true)
    }

    function exitCreateClipView() {
        setIsCreatingClip(false)
        setPlaybackSpeed(1)
        setIsLooping(true)
        setPlaybackStartAtMs(0)
        setPlaybackEndAtMs(Infinity)
        setSearchText(null)
    }

    function getUniqueClipTags() {
        let tagSet = new Set()
        if (videoRef.current) {
            videoRef.current.clips.forEach(({ tags }) =>
                tags.forEach((tag) => tagSet.add(tag))
            )
            videoRef.current.defaultTags.forEach((tag) => tagSet.delete(tag))
            return Array.from(tagSet)
        } else if (clipbookRef.current) {
            clipbookRef.current.clips.forEach((clips) => {
                clips.videoClip.tags.forEach((tag) => tagSet.add(tag))
            })
            return Array.from(tagSet)
        }
    }

    function updateEditedClip(clip) {
        setEditedClip(clip)
        setPlaybackSpeed(clip.defaultPlaybackSpeed)
        setIsLooping(clip.shouldLoopByDefault)
        setPlaybackStartAtMs(clip.startAtMs)
        setPlaybackEndAtMs(clip.endAtMs)
    }

    function exitClipEditView() {
        if (focusedClip) {
            const refreshedClip = videoRef.current.clips.find(
                (clip) => clip.id === editedClip.id
            )

            setFocusedClip(refreshedClip)
            setEditedClip(null)
            setPlaybackSpeed(refreshedClip.defaultPlaybackSpeed)
            setIsLooping(refreshedClip.shouldLoopByDefault)
            setPlaybackStartAtMs(refreshedClip.startAtMs)
            setPlaybackEndAtMs(refreshedClip.endAtMs)
        } else {
            setEditedClip(null)
            setPlaybackSpeed(1)
            setIsLooping(true)
            setPlaybackStartAtMs(0)
            setPlaybackEndAtMs(Infinity)
        }
    }

    function handleClose() {
        setShow(false)
    }

    function handleOpen(videoClip) {
        setShow(true)
        setSelectedVideoClipId(videoClip)
    }

    async function editClip({ comment, tags }) {
        try {
            await props.apiClient.put(
                `videos/${videoRef.current.id}/clips/${editedClip.id}`,
                {
                    comment,
                    startAtMs: Math.round(playbackStartAtMs),
                    endAtMs: Math.round(playbackEndAtMs),
                    tags,
                    defaultPlaybackSpeed: playbackSpeed,
                    shouldLoopByDefault: isLooping,
                }
            )
            await refreshVideo()
            exitClipEditView()
        } catch (err) {
            if (!(await props.apiClient.displayErrorToast(err))) {
                throw err
            }
        }
    }

    function getClipFocusView() {
        const hasPreviousClip = focusedClipIndex > 0
        const hasNextClip = focusedClipIndex < clips.length - 1

        return (
            <Container fluid="true" className="clipControlsContainer">
                <Row className="clipControlsTopFixed">
                    <Col>
                        <Button variant="warning" onClick={exitClipFocusView}>
                            View All
                        </Button>
                        <hr></hr>
                    </Col>
                    <Col className="nextPreviousButtons">
                        <Button
                            variant={
                                hasPreviousClip ? 'primary' : 'outline-primary'
                            }
                            disabled={!hasPreviousClip}
                            onClick={previousFocusedClip}
                        >
                            {<b>&#8592;</b>}
                        </Button>
                        {'   '}
                        <Button
                            variant={
                                hasNextClip ? 'primary' : 'outline-primary'
                            }
                            disabled={!hasNextClip}
                            onClick={nextFocusedClip}
                        >
                            {<b>&#8594;</b>}
                        </Button>
                        <hr></hr>
                    </Col>
                </Row>
                <Row className="clipControlsMidScroll">
                    <Col>
                        <div className="clipScrollDivHack">
                            <VideoClip
                                clip={focusedClip}
                                apiClient={props.apiClient}
                                deleteClipCallback={deleteClipCallback}
                                viewerStatus={viewerStatus}
                                clipEditCallback={updateEditedClip}
                                videoId={
                                    viewerMode === 'video'
                                        ? videoRef.current.id
                                        : focusedVideo.id
                                }
                                addToClipbookModalCallback={handleOpen}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>Speed</Col>
                    <Col>Loop</Col>
                    <Col>Autoplay</Col>
                </Row>
                <Row>
                    <Col>
                        <ClipSpeedControl
                            playbackSpeed={playbackSpeed}
                            selectSpeedCallback={updatePlaybackSpeed}
                        />
                    </Col>
                    <Col>
                        <ClipLoopControl
                            isLooping={isLooping}
                            toggleCallback={toggleLoopingState}
                        />
                    </Col>
                    <Col>
                        <AutoPlayControl
                            isAutoPlay={isAutoPlay}
                            toggleCallback={toggleAutoPlay}
                        />
                    </Col>
                </Row>
                <div className="clipControlsBottomFixed"></div>
            </Container>
        )
    }

    function getClipListView() {
        return (
            <Container fluid="true" className="clipControlsContainer">
                <Row noGutters className="clipControlsTopFixed">
                    <Col>
                        <SearchClips
                            searchClipCallback={handleSearchChange}
                            searchText={searchText}
                        />
                        <TagList
                            tags={getUniqueClipTags()}
                            selectTagCallback={updateSelectedTags}
                            selectedTags={selectedTags}
                            clearTagsCallback={clearSelectedTags}
                        />
                    </Col>
                </Row>
                <hr></hr>
                <Row noGutters className="clipControlsMidScroll">
                    <Col>
                        <div className="clipScrollDivHack">
                            <ClipList
                                clips={clips}
                                selectedClipCallback={updateFocusedClip}
                                apiClient={props.apiClient}
                                deleteClipCallback={deleteClipCallback}
                                viewerStatus={viewerStatus}
                                clipEditCallback={updateEditedClip}
                                videoId={focusedVideo ? focusedVideo.id : null}
                                enableSortClipList={enableSortableClipList()}
                                addToClipbookModalCallback={handleOpen}
                                clipbookId={
                                    viewerMode === 'clipbook'
                                        ? clipbookRef.current.id
                                        : null
                                }
                            />
                        </div>
                    </Col>
                </Row>
                <Row noGutters className="clipControlsBottomFixed">
                    <Col>
                        {viewerMode === 'video' &&
                        (viewerStatus === 'user' ||
                            viewerStatus === 'owner') ? (
                            <Button
                                block
                                variant="success"
                                size="lg"
                                onClick={enterCreateClipView}
                            >
                                Create Clip
                            </Button>
                        ) : (
                            <div />
                        )}
                    </Col>
                </Row>
            </Container>
        )
    }

    function getClipCreateView() {
        return (
            <Container
                fluid="true"
                className="clipControlsContainer clipControlsContainerActive"
            >
                <ClipCreationForm
                    submissionCallback={createClip}
                    exitCallback={exitCreateClipView}
                    existingTags={getUniqueClipTags()}
                    selectSpeedCallback={updatePlaybackSpeed}
                    toggleLoopingStateCallback={toggleLoopingState}
                    selectedPlaybackSpeed={playbackSpeed}
                    isLooping={isLooping}
                    defaultTags={videoRef.current.defaultTags}
                />
            </Container>
        )
    }

    function getClipEditView() {
        return (
            <Container
                fluid="true"
                className="clipControlsContainer clipControlsContainerActive"
            >
                <ClipCreationForm
                    submissionCallback={editClip}
                    exitCallback={exitClipEditView}
                    selectSpeedCallback={updatePlaybackSpeed}
                    toggleLoopingStateCallback={toggleLoopingState}
                    clip={editedClip}
                    defaultTags={videoRef.current.defaultTags}
                    existingTags={getUniqueClipTags()}
                    selectedPlaybackSpeed={playbackSpeed}
                    isLooping={isLooping}
                />
            </Container>
        )
    }

    if (!videoRef.current && !clipbookRef.current) {
        return <div />
    }

    let clipColumnView
    if (editedClip) {
        clipColumnView = getClipEditView()
    } else if (focusedClip) {
        clipColumnView = getClipFocusView()
    } else if (isCreatingClip) {
        clipColumnView = getClipCreateView()
    } else {
        clipColumnView = getClipListView()
    }

    return (
        <Container className="videoViewer" fluid="true">
            <Helmet>
                <title>
                    {viewerMode === 'video'
                        ? videoRef.current.title
                        : clipbookRef.current.title}{' '}
                    | ClipNotes
                </title>
            </Helmet>
            <Row noGutters className="videoViewerRow">
                <Col xl={9} lg={9} md={9} className="videoViewerLeftColumn">
                    <VideoPlayer
                        video={focusedVideo}
                        clipbook={clipbookRef ? clipbookRef.current : null}
                        clipId={focusedClip ? focusedClip.id : null}
                        isLooping={isLooping}
                        isAutoPlay={isAutoPlay}
                        playbackSpeed={playbackSpeed}
                        startAtMs={playbackStartAtMs}
                        endAtMs={playbackEndAtMs}
                        updatePlaybackCallback={updatePlaybackStartAndEnd}
                        nextClipCallback={nextFocusedClip}
                        viewerStatus={viewerStatus}
                        isEditingClip={editedClip}
                        isCreatingClip={isCreatingClip}
                    />
                </Col>
                <Col xl={3} lg={3} md={3} className="videoViewerRightColumn">
                    {clipColumnView}
                    <AddToClipbookModal
                        show={show}
                        apiClient={props.apiClient}
                        handleCloseCallback={handleClose}
                        videoClipId={selectedVideoClipId}
                    />
                    {viewerStatus === 'unknown' ? (
                        <Row className="videoViewerMobileCTA" noGutters>
                            <Col>Want to make your own ClipNotes?</Col>
                            <Col>
                                <a href="/?r=chen260P" className="unknownHover">
                                    <Button variant="primary">Sign Up</Button>
                                </a>
                            </Col>
                        </Row>
                    ) : (
                        <div></div>
                    )}
                </Col>
            </Row>
        </Container>
    )
}

VideoViewer.propTypes = {
    apiClient: PropTypes.object.isRequired,
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
}

export default VideoViewer
