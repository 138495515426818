import ky from 'ky'

export default async function (apiClient) {
    try {
        await apiClient.get('videos')

        return true
    } catch (err) {
        if (err instanceof ky.HTTPError && err.response.status === 401) {
            return false
        }

        throw err
    }
}
