import React, { useState, useEffect, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import Pagination from 'react-responsive-pagination'
import { Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { intersection } from 'lodash'

import AddToClipbookModal from './AddToClipbookModal'
import ClipList from './ClipList'

function MyClipNotes(props) {
    const [clipList, setClipList] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedTags, setSelectedTags] = useState([])
    const [show, setShow] = useState(false)
    const [selectedVideoClipId, setSelectedVideoClipId] = useState(false)

    const history = useHistory()

    async function refreshClips() {
        try {
            let clips = await props.apiClient.get('videos/clips')
            const ascendingSortedClips = [...clips].reverse()
            setClipList(ascendingSortedClips)
        } catch (err) {
            if (
                err.response &&
                (err.response.status === 401 || err.response.status === 403)
            ) {
                history.push('/login')
            } else if (!(await props.apiClient.displayErrorToast(err))) {
                throw err
            }
        }
    }

    useEffect(function () {
        refreshClips()

        if (props.location.state) {
            setSelectedTags(props.location.state.tagHistoryState)
            setCurrentPage(props.location.state.pageHistoryState)
        }

        return () => {}
    }, [])

    useEffect(() => {
        if (props.location.state && props.location.state.pageHistoryState) {
            setCurrentPage(props.location.state.pageHistoryState)
        } else {
            setCurrentPage(pageCount)
        }
    }, [clipList, props.location.state])

    if (!clipList) {
        return <div />
    }

    function navToClip(clip) {
        window.location.assign('watch/' + clip.video.id + '?clip=' + clip.id)
    }

    const perPage = 20
    const offset = (currentPage - 1) * perPage
    const clipListPage = getRelevantClips().slice(offset, offset + perPage)
    const pageCount = Math.ceil(getRelevantClips().length / perPage)

    function handlePageClick(page) {
        setCurrentPage(page)

        history.replace({
            location: '/myclipnotes',
            state: {
                pageHistoryState: page,
                tagHistoryState: selectedTags,
            },
        })
    }

    function getRelevantClips(tag = selectedTags) {
        if (tag && tag.length > 0) {
            const selectedTagsArray = tag.map((t) => t.value)
            const selectedTagsFilterFunction =
                selectedTagsArray.length > 0
                    ? (clip) =>
                          intersection(clip.tags, selectedTagsArray).length ===
                          selectedTagsArray.length
                    : (clip) => true
            return clipList.filter(selectedTagsFilterFunction)
        }
        return clipList
    }

    function getUniqueClipTags() {
        const relevantClips = getRelevantClips()

        if (relevantClips) {
            const tagSet = new Set()

            relevantClips.forEach((clip) =>
                clip.tags.forEach((tag) => tagSet.add(tag))
            )

            const uniqueClipTags = Array.from(tagSet).sort((a, b) =>
                a.toLowerCase().localeCompare(b.toLowerCase())
            )

            return uniqueClipTags.map((tag) => ({ value: tag, label: tag }))
        }
    }

    function updateSelectedTags(tag) {
        setSelectedTags(tag)

        const relevantClips = getRelevantClips(tag)
        const pageCount = Math.ceil(relevantClips.length / perPage)
        const currentPage = Math.min(pageCount, currentPage)

        setCurrentPage(currentPage)

        history.replace({
            location: '/myclipnotes',
            state: { pageHistoryState: currentPage, tagHistoryState: tag },
        })
    }

    function handleClose() {
        setShow(false)
    }

    function handleOpen(videoClip) {
        setShow(true)
        setSelectedVideoClipId(videoClip)
    }

    return (
        <Fragment>
            <Container className="myClipNotes">
                <Helmet>
                    <title>My ClipNotes | ClipNotes</title>
                </Helmet>
                <AddToClipbookModal
                    show={show}
                    apiClient={props.apiClient}
                    handleCloseCallback={handleClose}
                    videoClipId={selectedVideoClipId}
                />
                <h2>All ClipNotes</h2>
                <Select
                    isMulti
                    isSearchable
                    className="selectDropdown"
                    defaultValue={selectedTags}
                    onChange={updateSelectedTags}
                    options={getUniqueClipTags()}
                    placeholder="Search by tags..."
                />
                <Pagination
                    total={pageCount}
                    current={currentPage}
                    onPageChange={handlePageClick}
                />
                <ClipList
                    clips={clipListPage}
                    selectedClipCallback={navToClip}
                    apiClient={props.apiClient}
                    viewerStatus={'owner'}
                    addToClipbookModalCallback={handleOpen}
                />
                <Pagination
                    total={pageCount}
                    current={currentPage}
                    onPageChange={handlePageClick}
                />
            </Container>
        </Fragment>
    )
}

MyClipNotes.propTypes = {
    apiClient: PropTypes.object.isRequired,
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
}

export default MyClipNotes
