import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { Button, Modal, ListGroup } from 'react-bootstrap'
import { toast } from 'react-toastify'

function AddToClipbookModal(props) {
    const [clipbookList, setClipbookList] = useState(null)
    const [selectedClipbookId, setSelectedClipbookId] = useState(null)

    async function refreshClipbooks() {
        const clipbooks = await props.apiClient.get('clipbooks')
        setClipbookList(clipbooks)
    }

    async function handleSubmit() {
        const clipArray = [props.videoClipId]

        try {
            await props.apiClient.post(
                `clipbooks/${selectedClipbookId}/clips`,
                clipArray
            )

            toast.success('Successfully added to Clipbook', {
                autoClose: 3000,
            })
        } catch (err) {
            if (!(await props.apiClient.displayErrorToast(err))) {
                throw err
            }
        }
        handleCloseModal()
    }

    function handleCloseModal() {
        setSelectedClipbookId(null)
        props.handleCloseCallback()
    }

    useEffect(function () {
        refreshClipbooks()

        return () => {}
    }, [])

    if (!clipbookList) {
        return <div />
    }

    const clipbookListItems = clipbookList.map((clipbook, index) => {
        return (
            <ListGroup.Item
                key={clipbook.id}
                action
                href={`#${index}`}
                onClick={() => {
                    setSelectedClipbookId(clipbook.id)
                }}
            >
                {clipbook.title}
            </ListGroup.Item>
        )
    })

    return (
        <Modal
            show={props.show}
            onHide={handleCloseModal}
            centered
            size="sm"
            scrollable
            fullscreen="true"
        >
            <Modal.Header closeButton>
                <Modal.Title>Choose a Clipbook</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup variant="flush">{clipbookListItems}</ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    disabled={!selectedClipbookId}
                    onClick={handleSubmit}
                >
                    Add to Clipbook
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

AddToClipbookModal.propTypes = {
    apiClient: PropTypes.function,
    show: PropTypes.bool,
    handleCloseCallback: PropTypes.function,
    videoClipId: PropTypes.string,
}

export default AddToClipbookModal
