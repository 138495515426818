import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Link } from 'react-router-dom'
import { Button, Container, Form, Row } from 'react-bootstrap'

function SignUp(props) {
    const [email, setEmail] = useState(undefined)
    const [password, setPassword] = useState(undefined)
    const [passwordConfirmation, setPasswordConfirmation] = useState(undefined)
    const [isNewlySignedUp, setIsNewlySignedUp] = useState(false)
    const [agreesToTerms, setAgreesToTerms] = useState(false)

    const updateEmail = (e) => setEmail(e.target.value)
    const updatePassword = (e) => setPassword(e.target.value)
    const updatePasswordConfirmation = (e) =>
        setPasswordConfirmation(e.target.value)
    const updateAgreesToTerms = (e) => setAgreesToTerms(e.target.checked)

    const handleSubmit = async function () {
        try {
            await props.apiClient.post('users', {
                authenticationStrategy: {
                    type: 'emailAndPassword',
                    email,
                    password,
                },
            })
        } catch (err) {
            if (!(await props.apiClient.displayErrorToast(err))) {
                throw err
            }
        }

        setIsNewlySignedUp(true)
        props.setIsLoggedIn(true)
    }

    const successfulSignupRedirect =
        isNewlySignedUp || props.isLoggedIn ? (
            <Redirect
                to={{
                    pathname: '/myvideos',
                    state: { isNewSignup: isNewlySignedUp },
                }}
            />
        ) : (
            <div />
        )

    const doPasswordsMatch = password === passwordConfirmation
    const passwordsMustMatchText = !doPasswordsMatch
        ? 'Passwords must match'
        : ''

    return (
        <Container fluid className="centeredContainer">
            {successfulSignupRedirect}
            <img
                src="../images/ClipNotes_Logo_Main_cropped.svg"
                className="loginSignUpLogo"
            />
            <div className="formArea">
                <Form className="loginSignUpForm">
                    <Form.Group as={Row} controlId="emailField">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            required
                            type="email"
                            onChange={updateEmail}
                        />
                    </Form.Group>
                    <Form.Group as={Row} controlId="passwordField">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            required
                            type="password"
                            onChange={updatePassword}
                        />
                    </Form.Group>
                    <Form.Group as={Row} controlId="confirmPasswordField">
                        <Form.Label>Confirm password</Form.Label>
                        <Form.Control
                            required
                            type="password"
                            onChange={updatePasswordConfirmation}
                        />
                        <div className="text-danger">
                            {passwordsMustMatchText}
                        </div>
                    </Form.Group>
                    <Form.Group as={Row} controlId="agreeToTerms">
                        <Form.Check
                            required
                            label="By creating an account with ClipNotes, I agree and understand the terms of the Beta Agreement.  Beta software is provided AS-IS with no guarantees of support or functionality, access can be revoked at any time, and all data may be lost."
                            feedback="You must agree before signing up."
                            onChange={updateAgreesToTerms}
                        />
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Button
                            variant="success"
                            onClick={handleSubmit}
                            disabled={
                                !email ||
                                !password ||
                                !passwordConfirmation ||
                                !doPasswordsMatch ||
                                !agreesToTerms
                            }
                            block
                        >
                            Create Account
                        </Button>
                    </Form.Group>
                </Form>
                <div style={{ textAlign: 'center' }}>
                    <p>Already have an account?</p>
                    <p>
                        <Link to="/login">Login here</Link>
                    </p>
                </div>
            </div>
        </Container>
    )
}

SignUp.propTypes = {
    apiClient: PropTypes.object.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    setIsLoggedIn: PropTypes.func.isRequired,
}

export default SignUp
