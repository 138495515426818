// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag-list-icon {
    font-size: 1.5em;
    color: #007bff;
    margin-right: .5em;
    vertical-align: middle;
}

.tag-list-clear-icon {
    cursor: pointer;
    color: #dc3545;
}

.tag-list {
    margin-top: 1em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.tag-list-empty-text {
    margin-left: .5em;
    color: #888;
}`, "",{"version":3,"sources":["webpack://./css/components/TagList.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf","sourcesContent":[".tag-list-icon {\n    font-size: 1.5em;\n    color: #007bff;\n    margin-right: .5em;\n    vertical-align: middle;\n}\n\n.tag-list-clear-icon {\n    cursor: pointer;\n    color: #dc3545;\n}\n\n.tag-list {\n    margin-top: 1em;\n    display: flex;\n    flex-wrap: wrap;\n    align-items: center;\n}\n\n.tag-list-empty-text {\n    margin-left: .5em;\n    color: #888;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
