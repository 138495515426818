import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'

function Tag(props) {
    const handleClick = (e) => {
        ;(props.selectTagCallback || function () {})(props.tag)
    }

    return (
        <Button
            variant={props.isSelected ? 'primary' : 'outline-primary'}
            onClick={handleClick}
            className="tagFilter"
            size="sm"
        >
            {props.tag}
        </Button>
    )
}

Tag.propTypes = {
    tag: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
    selectTagCallback: PropTypes.func,
}

export default Tag
