import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import ReactTags from 'react-tag-autocomplete'

function ClipbookCreationForm(props) {
    const [title, setTitle] = useState('')
    const [summary, setSummary] = useState('')
    const [selectedTags, setSelectedTags] = useState([])

    const reactTags = useRef(null)

    const handleSubmit = async function () {
        const tags = selectedTags.map((selectedTag) => selectedTag.name)

        await props.submissionCallback({
            title: title,
            summary: summary,
            tags: tags,
        })

        setTitle('')
        setSummary('')
        setSelectedTags([])
    }

    const onDelete = (i) => {
        const tags = selectedTags.slice(0)
        tags.splice(i, 1)
        setSelectedTags(tags)
    }

    const onAddition = (tag) => {
        const tags = [].concat(selectedTags, tag)
        setSelectedTags(tags)
    }

    return (
        <Container>
            <Row>
                <Col
                    className="videoSubmissionContainer"
                    xl={6}
                    l={6}
                    md={12}
                    sm={12}
                    style={{ padding: '0px', paddingTop: '1em' }}
                >
                    <Form className="videoSubmissionForm">
                        <Form.Group
                            as={Row}
                            controlId="titleUrlForm"
                            className="requiredForm"
                        >
                            <Form.Label column md={3}>
                                Title
                            </Form.Label>
                            <Col md={9}>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Title of Clipbook"
                                    onChange={(e) => setTitle(e.target.value)}
                                    value={title}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="defaultTagsForm">
                            <Form.Label column md={3}>
                                Default Tags
                            </Form.Label>
                            <Col md={9}>
                                <ReactTags
                                    ref={reactTags}
                                    tags={selectedTags}
                                    onDelete={onDelete}
                                    onAddition={onAddition}
                                    autoresize={false}
                                    allowNew={true}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="summaryUrlForm">
                            <Form.Label column md={3}>
                                Summary
                            </Form.Label>
                            <Col md={9}>
                                <Form.Control
                                    type="text"
                                    placeholder="Additional Descriptive Text"
                                    onChange={(e) => setSummary(e.target.value)}
                                    value={summary}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col md={3} />
                            <Col md={9} className="justify-content-center">
                                <Button
                                    variant="success"
                                    onClick={handleSubmit}
                                    disabled={!title}
                                    block
                                >
                                    Create Clipbook
                                </Button>
                            </Col>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

ClipbookCreationForm.propTypes = {
    submissionCallback: PropTypes.func.isRequired,
}

export default ClipbookCreationForm
