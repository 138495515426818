import React from 'react'
import PropTypes from 'prop-types'
import { MdFilterAlt, MdFilterAltOff } from 'react-icons/md'
import Tag from './Tag'
import '../css/components/TagList.css'

function TagList(props) {
    const sortedTags = props.tags.sort()
    const tagElements = []

    sortedTags.forEach((tag) =>
        tagElements.push(
            <Tag
                key={tag}
                tag={tag}
                selectTagCallback={props.selectTagCallback}
                isSelected={props.selectedTags.includes(tag)}
            />
        )
    )

    function handleClearTags() {
        props.clearTagsCallback()
    }

    return (
        <div className="tag-list">
            {props.selectedTags.length === 0 ? (
                <MdFilterAlt className="tag-list-icon" />
            ) : (
                <MdFilterAltOff
                    className="tag-list-icon tag-list-clear-icon"
                    onClick={handleClearTags}
                />
            )}
            {props.tags.length === 0 ? (
                <span className="tag-list-empty-text">
                    Add tags to stay organized!
                </span>
            ) : (
                tagElements
            )}
        </div>
    )
}

TagList.propTypes = {
    tags: PropTypes.array.isRequired,
    selectedTags: PropTypes.array.isRequired,
    selectTagCallback: PropTypes.func,
    clearTagsCallback: PropTypes.func,
}

export default TagList
