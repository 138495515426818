import React from 'react'
import PropTypes from 'prop-types'
import { Form, InputGroup } from 'react-bootstrap'
import { FaSearch } from 'react-icons/fa'
import '../css/components/SearchClips.css'

function SearchClips(props) {
    return (
        <div className="width100">
            <Form>
                <InputGroup className="search-clips-input">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <FaSearch />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        type="text"
                        placeholder="Search clips here..."
                        onChange={props.searchClipCallback}
                        value={props.searchText}
                        onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                        }}
                    />
                </InputGroup>
            </Form>
        </div>
    )
}

SearchClips.propTypes = {
    clips: PropTypes.array,
    searchClipCallback: PropTypes.func,
    searchText: PropTypes.string,
}

export default SearchClips
