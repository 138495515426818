import React from 'react'
import PropTypes from 'prop-types'
import { Card, Dropdown } from 'react-bootstrap'

function VideoCard(props) {
    const handleDropdownClick = (e) => {
        e.preventDefault()
    }

    const deleteVideo = async function () {
        try {
            await props.apiClient.delete(`videos/${props.id}`)
        } catch (err) {
            if (!(await props.apiClient.displayErrorToast(err))) {
                throw err
            }
        }
        await props.deleteVideoCallback()
    }

    const deleteClipbook = async function () {
        try {
            await props.apiClient.delete(`clipbooks/${props.id}`)
        } catch (err) {
            if (!(await props.apiClient.displayErrorToast(err))) {
                throw err
            }
        }
        await props.deleteVideoCallback()
    }

    return (
        <Card
            className="videoCards"
            bg="light"
            style={{ minWidth: '18rem', border: '0' }}
        >
            <div className="videoCardImageDiv">
                <Card.Img
                    variant="top"
                    className="videoCardImage"
                    src={props.thumbnailSrc}
                />
            </div>
            <div className="dropdownDiv">
                <Dropdown onClick={handleDropdownClick}>
                    <Dropdown.Toggle
                        variant="outline-warning"
                        className="videoClipDropdown"
                        size="sm"
                    />
                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={
                                props.cardType === 'video'
                                    ? deleteVideo
                                    : deleteClipbook
                            }
                        >
                            Delete
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <Card.Text>{props.summary}</Card.Text>
                <small className="text-muted">{props.formattedTags}</small>
            </Card.Body>
        </Card>
    )
}

VideoCard.propTypes = {
    sourceUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    thumbnailSrc: PropTypes.string.isRequired,
    formattedTags: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    deleteVideoCallback: PropTypes.func.isRequired,
    apiClient: PropTypes.object.isRequired,
    cardType: PropTypes.string.isRequired,
}

export default VideoCard
