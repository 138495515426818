import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import ReactTags from 'react-tag-autocomplete'
import ClipLoopControl from './ClipLoopControl'
import ClipSpeedControl from './ClipSpeedControl'

function ClipCreationForm(props) {
    const allTags = new Set()
    props.existingTags.forEach((tag) => allTags.add(tag))
    props.defaultTags.forEach((tag) => allTags.add(tag))

    const tagSuggestions = Array.from(allTags).map(function (tag, index) {
        return {
            id: index,
            name: tag,
        }
    })

    let startingTags = null
    let startingComment = null
    if (props.clip) {
        startingTags = props.clip.tags
        startingComment = props.clip.thread.content
    } else {
        startingTags = props.defaultTags
        startingComment = ''
    }

    const [comment, setComment] = useState(startingComment)
    const [selectedTags, setSelectedTags] = useState(
        startingTags.map((tags) => {
            return tagSuggestions.find(
                (tagSuggestion) => tagSuggestion.name === tags
            )
        })
    )

    const reactTags = useRef(null)

    const handleSubmit = () => {
        const tags = selectedTags.map((selectedTag) => selectedTag.name)

        props.submissionCallback({
            comment: comment,
            tags,
        })
    }

    const onDelete = (i) => {
        const tags = selectedTags.slice(0)
        tags.splice(i, 1)
        setSelectedTags(tags)
    }

    const onAddition = (tag) => {
        const tags = [].concat(selectedTags, tag)
        setSelectedTags(tags)
    }

    return (
        <Container fluid="true" className="clipCreationFormContainer">
            <Row className="clipControlsTopFixed">
                <Button onClick={props.exitCallback} variant="warning">
                    Back
                </Button>
                <hr></hr>
            </Row>
            <Row>{props.clip ? 'Edit Clip' : 'Create Clip'}</Row>
            <div className="clipControlsCreateClipForms">
                <Row>
                    <ReactTags
                        ref={reactTags}
                        tags={selectedTags}
                        suggestions={tagSuggestions}
                        onDelete={onDelete}
                        onAddition={onAddition}
                        autoresize={false}
                        allowNew={true}
                    />
                </Row>
                <Row>
                    <Form.Group controlId="createClipForm" className="width100">
                        <Form.Control
                            as="textarea"
                            rows="12"
                            value={comment}
                            placeholder="Notes go here..."
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </Form.Group>
                </Row>
                <Row>
                    <Col>Speed</Col>
                    <Col>Loop</Col>
                </Row>
                <Row>
                    <Col>
                        <ClipSpeedControl
                            playbackSpeed={props.selectedPlaybackSpeed}
                            selectSpeedCallback={props.selectSpeedCallback}
                        />
                    </Col>
                    <Col>
                        <ClipLoopControl
                            isLooping={props.isLooping}
                            toggleCallback={props.toggleLoopingStateCallback}
                        />
                    </Col>
                </Row>
            </div>
            <div className="clipControlsBottomFixed">
                <Row>
                    <Button
                        onClick={handleSubmit}
                        variant="success"
                        size="lg"
                        block
                        className="submitClipButton"
                    >
                        {props.clip ? 'Save Changes' : 'Submit'}
                    </Button>
                </Row>
            </div>
        </Container>
    )
}

ClipCreationForm.propTypes = {
    submissionCallback: PropTypes.func.isRequired,
    exitCallback: PropTypes.func,
    existingTags: PropTypes.array.isRequired,
    selectSpeedCallback: PropTypes.func,
    toggleLoopingStateCallback: PropTypes.func,
    selectedPlaybackSpeed: PropTypes.number,
    isLooping: PropTypes.bool,
    defaultTags: PropTypes.array.isRequired,
    clip: PropTypes.object,
}

export default ClipCreationForm
