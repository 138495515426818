import { Duration } from 'luxon'

export default function formatDurationToTimestamp(duration) {
    if (
        Duration.fromObject(duration).valueOf() >
        Duration.fromObject({ hours: 1 }).valueOf()
    ) {
        return Duration.fromObject(duration).toFormat('h:mm:ss')
    } else {
        return Duration.fromObject(duration).toFormat('m:ss')
    }
}
