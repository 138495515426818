import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import VideoCard from './VideoCard'
import youtubeThumbnail from '../util/youtubeThumbnail'

function VideoCardDeck(props) {
    const videoCardDeck = props.videoList.map((item) => {
        let cardType
        let path
        if (item.defaultTags) {
            cardType = 'video'
            path = 'watch'
        } else {
            cardType = 'clipbook'
            path = 'clipbook'
        }
        const unformattedTags = item.defaultTags || item.tags
        const formattedTags = unformattedTags.map((tag) => `#${tag}`).join(' ')

        const thumbnail = youtubeThumbnail(item.sourceUrl).high
        return (
            <Col key={item.id} className="d-flex justify-content-center">
                <Link
                    to={`/${path}/${item.id}`}
                    className="d-flex justify-content-center"
                    style={{ textDecoration: 'none', color: 'black' }}
                >
                    <VideoCard
                        sourceUrl={item.sourceUrl}
                        title={item.title}
                        summary={item.summary}
                        thumbnailSrc={thumbnail}
                        formattedTags={formattedTags}
                        id={item.id}
                        deleteVideoCallback={props.deleteVideoCallback}
                        apiClient={props.apiClient}
                        cardType={cardType}
                    />
                </Link>
            </Col>
        )
    })

    return (
        <Row xs={1} md={2} lg={3}>
            {videoCardDeck}
        </Row>
    )
}

VideoCardDeck.propTypes = {
    videoList: PropTypes.array.isRequired,
    deleteVideoCallback: PropTypes.func.isRequired,
    apiClient: PropTypes.object.isRequired,
}

export default VideoCardDeck
