import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Duration } from 'luxon'
import Sticky from 'react-stickynode'
import { Container, Row, Col, Button, ButtonGroup } from 'react-bootstrap'
import ReactPlayer from 'react-player'
import ReactSlider from './ReactSlider'
import formatDurationToTimestamp from '../util/formatDurationToTimestamp.js'
import usePrevious from '../util/usePrevious'
import useIsMobile from '../util/useIsMobile.js'
import '../images/ClipNotes_Logo_Main_cropped.svg'
import '../images/loading.svg'

function VideoPlayer(props) {
    const [isPlaying, setIsPlaying] = useState(true)
    const [playedFraction, setPlayedFraction] = useState(0)

    const playerRef = useRef()
    const previousClipId = usePrevious(props.clipId)
    const isMobile = useIsMobile()

    const isFirstRenderRef = useRef()
    if (isFirstRenderRef.current === undefined) {
        isFirstRenderRef.current = true
    } else {
        isFirstRenderRef.current = false
    }

    if (
        isFirstRenderRef.current === false &&
        props.clipId &&
        props.clipId !== previousClipId
    ) {
        // this seek must be avoided on the initial render, because the player is not initialized yet
        seekToTimeMs(props.startAtMs)
    }

    function getPlaybackSpeed() {
        return props.playbackSpeed || 1
    }

    function isEndAtMsInitialized() {
        return props.endAtMs !== Infinity && props.endAtMs
    }

    function updateDuration(durationS) {
        props.updatePlaybackCallback({
            startAtMs: props.startAtMs,
            endAtMs: isEndAtMsInitialized() ? props.endAtMs : durationS * 1000,
        })
    }

    function getVideoDurationMs() {
        if (!playerRef.current) {
            return null
        }

        return playerRef.current.getDuration() * 1000
    }

    function getStartAtFraction() {
        const videoDurationMs = getVideoDurationMs()
        if (!videoDurationMs) {
            return 0
        }

        return props.startAtMs / videoDurationMs
    }

    function getEndAtFraction() {
        const videoDurationMs = getVideoDurationMs()
        if (props.endAtMs === Infinity || !videoDurationMs) {
            return 1
        }

        return props.endAtMs / videoDurationMs
    }

    function seekToTimeMs(targetTimeMs) {
        playerRef.current.seekTo(targetTimeMs / getVideoDurationMs())
    }

    function handlePause() {
        setIsPlaying(false)
    }

    function handlePlay() {
        setIsPlaying(true)
    }

    function handleStartUpdate(value) {
        props.updatePlaybackCallback({
            startAtMs: parseFloat(value[0]) * getVideoDurationMs(),
            endAtMs: props.endAtMs,
        })
    }

    function handleEndUpdate(value) {
        props.updatePlaybackCallback({
            startAtMs: props.startAtMs,
            endAtMs: parseFloat(value[1]) * getVideoDurationMs(),
        })
    }

    function handleSeekChange(value) {
        playerRef.current.seekTo(parseFloat(value[0]))
    }

    function handleProgress(e) {
        //added a buffer to the startAt boundary because of mobile players getting stuck when trying to loop
        const startAtMsBuffer = 2000

        setPlayedFraction(parseFloat(e.played))

        const videoDurationMs = getVideoDurationMs()
        if (isPlaying === true) {
            if (props.isLooping) {
                if (
                    e.played * videoDurationMs > props.endAtMs ||
                    e.played * videoDurationMs + startAtMsBuffer <
                        props.startAtMs
                ) {
                    playerRef.current.seekTo(getStartAtFraction())
                }
            } else if (props.isLooping === false) {
                if (e.played * videoDurationMs > props.endAtMs) {
                    if (props.isEditingClip || props.isCreatingClip) {
                        setIsPlaying(false)
                    } else {
                        if (
                            props.isAutoPlay === true &&
                            props.nextClipCallback() === true
                        ) {
                            props.nextClipCallback()
                        } else {
                            setIsPlaying(false)
                        }
                    }
                } else if (
                    e.played * videoDurationMs + startAtMsBuffer <
                    props.startAtMs
                ) {
                    playerRef.current.seekTo(getStartAtFraction())
                }
            }
        } else {
            if (
                e.played * videoDurationMs > props.endAtMs ||
                e.played * videoDurationMs + startAtMsBuffer < props.startAtMs
            ) {
                playerRef.current.seekTo(getStartAtFraction())
            }
        }
    }

    function handlePlayerInitialization() {
        props.updatePlaybackCallback({
            startAtMs: props.startAtMs,
            endAtMs: isEndAtMsInitialized()
                ? props.endAtMs
                : getVideoDurationMs() || props.endAtMs,
        })
    }

    function setCurrentPlayedFractionToStartTime(e) {
        props.updatePlaybackCallback({
            startAtMs: playedFraction * getVideoDurationMs(),
            endAtMs: props.endAtMs,
        })
    }

    function setCurrentPlayedFractionToEndTime(e) {
        props.updatePlaybackCallback({
            startAtMs: props.startAtMs,
            endAtMs: playedFraction * getVideoDurationMs(),
        })
    }

    function roundTime(timeMs) {
        if (timeMs < 0) {
            return 0
        }

        const videoDurationMs = getVideoDurationMs()
        if (timeMs > videoDurationMs) {
            return videoDurationMs
        }

        return timeMs
    }

    /**
     * Gets an event handler that makes the desired time adjustment when called.
     *
     * @param {String} timeProperty - the time property to adjust (i.e. startAtMs)
     * @param {Luxon.Duration} adjustment - the adjustment to the time property desired
     *
     * @returns {Function}
     */
    function getTimeAdjustmentHandler(timeProperty, adjustment) {
        return (e) => {
            const newPlayback = {
                startAtMs: props.startAtMs,
                endAtMs: props.endAtMs,
            }
            newPlayback[timeProperty] += adjustment.as('milliseconds')
            newPlayback[timeProperty] = roundTime(newPlayback[timeProperty])
            props.updatePlaybackCallback(newPlayback)
        }
    }

    function videoPlayerControls() {
        const videoDurationMs = getVideoDurationMs()

        if (!videoDurationMs) {
            return null
        }

        return (
            <div className="videoPlayerControls">
                <span className="currentTime">
                    {formatDurationToTimestamp({
                        milliseconds: playedFraction * videoDurationMs,
                    })}
                    {' / '}
                    {formatDurationToTimestamp({
                        milliseconds: videoDurationMs,
                    })}
                </span>
                <Row>
                    <Col className="playbackTimeRow">
                        <span className="timeBox">
                            {formatDurationToTimestamp({
                                milliseconds: props.startAtMs,
                            })}
                        </span>
                        <span>{'\u2014'}</span>
                        <span className="timeBox">
                            {formatDurationToTimestamp({
                                milliseconds: props.endAtMs,
                            })}
                        </span>
                    </Col>
                </Row>
                {props.clipbook ? (
                    <div />
                ) : (
                    <div>
                        <Row>
                            <Col>
                                <ReactSlider
                                    startSliderFraction={getStartAtFraction()}
                                    endSliderFraction={getEndAtFraction()}
                                    onStartUpdate={handleStartUpdate}
                                    onEndUpdate={handleEndUpdate}
                                    onSeekChange={handleSeekChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="quickSetButtonRow">
                                <ButtonGroup className="quickSetButtonGroup">
                                    <Button
                                        onClick={getTimeAdjustmentHandler(
                                            'startAtMs',
                                            Duration.fromObject({ seconds: -1 })
                                        )}
                                        size="sm"
                                        className="setTimeButton"
                                        variant="outline-primary"
                                    >
                                        -1
                                    </Button>
                                    <Button
                                        onClick={
                                            setCurrentPlayedFractionToStartTime
                                        }
                                        size="sm"
                                        className="setTimeButton"
                                    >
                                        Quick Set
                                    </Button>
                                    <Button
                                        onClick={getTimeAdjustmentHandler(
                                            'startAtMs',
                                            Duration.fromObject({ seconds: 1 })
                                        )}
                                        size="sm"
                                        className="setTimeButton"
                                        variant="outline-primary"
                                    >
                                        +1
                                    </Button>
                                </ButtonGroup>
                                <ButtonGroup className="quickSetButtonGroup">
                                    <Button
                                        onClick={getTimeAdjustmentHandler(
                                            'endAtMs',
                                            Duration.fromObject({ seconds: -1 })
                                        )}
                                        size="sm"
                                        className="setTimeButton"
                                        variant="outline-primary"
                                    >
                                        -1
                                    </Button>
                                    <Button
                                        onClick={
                                            setCurrentPlayedFractionToEndTime
                                        }
                                        size="sm"
                                        className="setTimeButton"
                                    >
                                        Quick Set
                                    </Button>
                                    <Button
                                        onClick={getTimeAdjustmentHandler(
                                            'endAtMs',
                                            Duration.fromObject({ seconds: 1 })
                                        )}
                                        size="sm"
                                        className="setTimeButton"
                                        variant="outline-primary"
                                    >
                                        +1
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
        )
    }

    function emptyPlayerControls() {
        return (
            <div className="videoPlayerControls">
                <img src="../images/loading.svg" className="imgCenter" />
            </div>
        )
    }

    function unknownPlayerControls() {
        return (
            <div className="videoPlayerControls">
                <Container fluid className="unknownPlayerControls">
                    <Row className="align-items-center">
                        <Col>
                            <img
                                src="../images/ClipNotes_Logo_Main_cropped.svg"
                                className="unknownPlayerControlsImage"
                            />
                        </Col>
                        <Col>
                            <p>Want to make your own ClipNotes? {'\n'}</p>
                            <a href="/?r=chen260P" className="unknownHover">
                                <Button variant="primary" size="lg">
                                    Get Early Access
                                </Button>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    const viewerStatus = props.viewerStatus
    let playerControls

    if (viewerStatus === 'owner' || viewerStatus === 'user') {
        playerControls = videoPlayerControls()
    } else if (viewerStatus === 'unknown') {
        playerControls = unknownPlayerControls()
    } else {
        playerControls = emptyPlayerControls()
    }

    return (
        <Container fluid className="videoPlayerContainer">
            <Row className="videoPlayer">
                <Sticky
                    enabled={isMobile}
                    top={60}
                    innerZ={10}
                    innerClass={'videoPlayer'}
                >
                    <ReactPlayer
                        url={props.video ? props.video.sourceUrl : null}
                        playing={isPlaying}
                        playbackRate={getPlaybackSpeed()}
                        width="100%"
                        height="100%"
                        controls={true}
                        volume={1}
                        muted={isMobile}
                        progressInterval={100}
                        onProgress={handleProgress}
                        onDuration={updateDuration}
                        onPlay={handlePlay}
                        onPause={handlePause}
                        onReady={handlePlayerInitialization}
                        ref={(player) => {
                            playerRef.current = player
                        }}
                    />
                </Sticky>
            </Row>
            <div>
                <p className="videoTitle">
                    {props.clipbook ? props.clipbook.title : props.video.title}
                </p>
                {playerControls}
            </div>
        </Container>
    )
}

VideoPlayer.propTypes = {
    video: PropTypes.object,
    clipId: PropTypes.string,
    isLooping: PropTypes.bool,
    isAutoPlay: PropTypes.bool,
    playbackSpeed: PropTypes.number,
    startAtMs: PropTypes.number.isRequired,
    endAtMs: PropTypes.number.isRequired,
    updatePlaybackCallback: PropTypes.func.isRequired,
    nextClipCallback: PropTypes.func,
    viewerStatus: PropTypes.string,
    isEditingClip: PropTypes.object,
    isCreatingClip: PropTypes.bool,
    clipbook: PropTypes.object,
}

export default VideoPlayer
