import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../css/style.css'
import '../images/unsplash.jpg'
import '../images/ClipNotes_Logo_Secondary_cropped.svg'

function ComingSoon(props) {
    return (
        <Container fluid="true" expand="xl">
            <div className="comingSoon">
                <div className="vimeo-wrapper">
                    <iframe
                        src="https://player.vimeo.com/video/496782800?background=1&autoplay=1&loop=1&byline=0&title=0"
                        frameBorder="0"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen
                    ></iframe>
                </div>
                <span className="blackOverlay" />
                <div className="landingContainer">
                    <span className="spacing" />
                    <span className="spacing" />
                    <img
                        src="../images/ClipNotes_Logo_Secondary_cropped.svg"
                        className="comingSoonClipNotesLogo"
                    />
                    <span className="spacing"></span>
                    <h4 className="tagline">Don&apos;t just watch. Learn.</h4>
                    <span className="spacing"></span>
                    <Link to="/signup">
                        <Button size="lg" block>
                            Get Started Now
                        </Button>
                    </Link>
                    <span className="spacing"></span>
                    <p className="footer">
                        Copyright &#169; 2021 ClipNotes, LLC
                    </p>
                </div>
            </div>
        </Container>
    )
}

export default ComingSoon
