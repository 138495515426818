import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ListGroup } from 'react-bootstrap'
import VideoClip from './VideoClip'
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import {
    arrayMove,
    useSortable,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import {
    restrictToVerticalAxis,
    restrictToWindowEdges,
} from '@dnd-kit/modifiers'
import { CSS } from '@dnd-kit/utilities'
import useIsMobile from '../util/useIsMobile'

function ClipList(props) {
    const [videoClips, setVideoClips] = useState(props.clips)
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                delay: 200,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 400,
                tolerance: 10,
            },
        })
    )

    useEffect(() => {
        setVideoClips(props.clips)
    }, [props.clips])

    async function onDragEnd({ active, over }) {
        if (active.id !== over.id) {
            const videoClipsIds = videoClips.map((el) => el.id)
            const oldIndex = videoClipsIds.indexOf(active.id)
            const newIndex = videoClipsIds.indexOf(over.id)
            const newVideoClipOrder = arrayMove(videoClips, oldIndex, newIndex)
            setVideoClips(newVideoClipOrder) // sets new clip order

            const getOrder = (index) => {
                if (index === -1) {
                    return 0
                } else if (index === videoClips.length) {
                    return videoClips.length + 1
                } else {
                    return newVideoClipOrder[index].order
                }
            }
            const prev = getOrder(newIndex - 1)
            const next = getOrder(newIndex + 1)
            const clip = await editVideoClipOrder(
                newVideoClipOrder[newIndex].id,
                prev,
                next
            )
            newVideoClipOrder[newIndex].order = clip.order // updates order value in clip
            setVideoClips(newVideoClipOrder)
        }
    }

    async function editVideoClipOrder(clipId, prev, next) {
        try {
            if (props.clipbookId) {
                return await props.apiClient.put(
                    `clipbooks/${props.clipbookId}/clips/${clipId}`,
                    {
                        prev,
                        next,
                    }
                )
            } else {
                return await props.apiClient.put(
                    `videos/${props.videoId}/clips/${clipId}`,
                    {
                        prev,
                        next,
                    }
                )
            }
        } catch (err) {
            if (!(await props.apiClient.displayErrorToast(err))) {
                throw err
            }
        }
    }

    return (
        <DndContext
            sensors={sensors}
            onDragEnd={onDragEnd}
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
        >
            <ListGroup>
                <SortableContext
                    items={videoClips}
                    strategy={verticalListSortingStrategy}
                >
                    {videoClips.map((clip) => (
                        <SortableClip key={clip.id} clip={clip} {...props} />
                    ))}
                </SortableContext>
            </ListGroup>
        </DndContext>
    )
}

function SortableClip(props) {
    let disableSort = true
    const isMobile = useIsMobile()

    if (props.enableSortClipList === true && !isMobile) {
        disableSort = false
    }

    const { clip } = props
    const {
        isDragging,
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: clip.id, disabled: disableSort })

    const style = {
        position: 'relative',
        zIndex: isDragging ? 1 : undefined,
        transform: CSS.Translate.toString(transform),
        transition,
    }
    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <VideoClip
                clip={clip}
                key={clip.id}
                clipSelectCallback={
                    props.selectedClipCallback || function () {}
                }
                apiClient={props.apiClient}
                deleteClipCallback={props.deleteClipCallback}
                viewerStatus={props.viewerStatus}
                clipEditCallback={props.clipEditCallback}
                videoId={props.videoId}
                addToClipbookModalCallback={props.addToClipbookModalCallback}
            />
        </div>
    )
}

ClipList.propTypes = {
    clips: PropTypes.array.isRequired,
    selectedClipCallback: PropTypes.func,
    apiClient: PropTypes.object.isRequired,
    deleteClipCallback: PropTypes.func,
    viewerStatus: PropTypes.string,
    clipEditCallback: PropTypes.func,
    videoId: PropTypes.string,
    enableSortClipList: PropTypes.boolean,
    addToClipbookModalCallback: PropTypes.func,
    clipbookId: PropTypes.string,
}

SortableClip.propTypes = {
    clip: PropTypes.object.isRequired,
    selectedClipCallback: PropTypes.func,
    apiClient: PropTypes.object.isRequired,
    deleteClipCallback: PropTypes.func,
    viewerStatus: PropTypes.string,
    clipEditCallback: PropTypes.func,
    videoId: PropTypes.string,
    enableSortClipList: PropTypes.boolean,
    addToClipbookModalCallback: PropTypes.func,
    clipbookId: PropTypes.string,
}

export default ClipList
