// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-clips-input {
    border: 1px solid #007bff;
    border-radius: 4px;
  }
  
  .search-clips-input .form-control {
    border: none;
    box-shadow: none;
  }

  .search-clips-input .form-control:focus {
    box-shadow: none;
  }
  
  .search-clips-input .input-group-text {
    background-color: #fff;
    border: none;
    margin-right: -0.6em;
  }
  
  .search-clips-input .input-group-text svg {
    color: #007bff;
  }

  .search-clips-input:hover,
  .search-clips-input:focus-within {
    border-color: #0056b3;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .search-clips-input:hover .input-group-text svg,
  .search-clips-input:focus-within .input-group-text svg {
    color: #0056b3;
  }  

  @media only screen and (max-width: 480px) {
    .search-clips-input {
      display: none !important;
    }
  }
  
  @media only screen and (min-width: 481px) and (max-width: 767px) {
    .search-clips-input {
      display: none !important;
    }
  }`, "",{"version":3,"sources":["webpack://./css/components/SearchClips.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;IACtB,YAAY;IACZ,oBAAoB;EACtB;;EAEA;IACE,cAAc;EAChB;;EAEA;;IAEE,qBAAqB;IACrB,gDAAgD;EAClD;;EAEA;;IAEE,cAAc;EAChB;;EAEA;IACE;MACE,wBAAwB;IAC1B;EACF;;EAEA;IACE;MACE,wBAAwB;IAC1B;EACF","sourcesContent":[".search-clips-input {\n    border: 1px solid #007bff;\n    border-radius: 4px;\n  }\n  \n  .search-clips-input .form-control {\n    border: none;\n    box-shadow: none;\n  }\n\n  .search-clips-input .form-control:focus {\n    box-shadow: none;\n  }\n  \n  .search-clips-input .input-group-text {\n    background-color: #fff;\n    border: none;\n    margin-right: -0.6em;\n  }\n  \n  .search-clips-input .input-group-text svg {\n    color: #007bff;\n  }\n\n  .search-clips-input:hover,\n  .search-clips-input:focus-within {\n    border-color: #0056b3;\n    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);\n  }\n  \n  .search-clips-input:hover .input-group-text svg,\n  .search-clips-input:focus-within .input-group-text svg {\n    color: #0056b3;\n  }  \n\n  @media only screen and (max-width: 480px) {\n    .search-clips-input {\n      display: none !important;\n    }\n  }\n  \n  @media only screen and (min-width: 481px) and (max-width: 767px) {\n    .search-clips-input {\n      display: none !important;\n    }\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
