import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Slider, Handles, Rail, Tracks } from 'react-compound-slider'
import { Handle, SliderRail, Track } from './ReactSliderComponents'

const sliderStyle = {
    position: 'relative',
    width: '100%',
    padding: '1em 0em 1em 0em',
}

const domain = [0, 1]

function ReactSlider(props) {
    const defaultValues = [props.startSliderFraction, props.endSliderFraction]
    const [update, setUpdate] = useState(defaultValues)
    const [value, setValue] = useState(defaultValues)

    function onUpdate(e) {
        setUpdate(e)
        if (e[0] == update[0]) {
            props.onEndUpdate(e)
        } else {
            props.onStartUpdate(e)
        }
    }

    function onChange(e) {
        setValue(e)
        if (e[0] == value[0]) {
            props.onEndUpdate(e)
        } else {
            props.onStartUpdate(e)
            props.onSeekChange(e)
        }
    }

    return (
        <Slider
            rootStyle={sliderStyle}
            domain={domain}
            step={0.0001}
            mode={1}
            values={defaultValues}
            onUpdate={onUpdate}
            onChange={onChange}
        >
            <Rail>
                {({ getRailProps }) => (
                    <SliderRail getRailProps={getRailProps} />
                )}
            </Rail>
            <Handles>
                {({ handles, getHandleProps }) => (
                    <div className="slider-handles">
                        {handles.map((handle) => (
                            <Handle
                                key={handle.id}
                                handle={handle}
                                domain={domain}
                                getHandleProps={getHandleProps}
                            />
                        ))}
                    </div>
                )}
            </Handles>
            <Tracks left={false} right={false}>
                {({ tracks, getTrackProps }) => (
                    <div className="slider-tracks">
                        {tracks.map(({ id, source, target }) => (
                            <Track
                                key={id}
                                source={source}
                                target={target}
                                getTrackProps={getTrackProps}
                            />
                        ))}
                    </div>
                )}
            </Tracks>
        </Slider>
    )
}

ReactSlider.propTypes = {
    startSliderFraction: PropTypes.number,
    endSliderFraction: PropTypes.number,
    onStartUpdate: PropTypes.func,
    onEndUpdate: PropTypes.func,
    onSeekChange: PropTypes.func,
}

export default ReactSlider
