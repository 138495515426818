import React, { useEffect, useState, useRef } from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { Navbar, Nav, Container, Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import ComingSoon from './ComingSoon'
import VideoViewer from './VideoViewer'
import 'bootstrap/dist/css/bootstrap.css'
import PropTypes from 'prop-types'
import MyClipNotes from './MyClipNotes'
import MyVideos from './MyVideos'
import MyClipbooks from './MyClipbooks'
import RouteChangeTracker from './RouteChangeTracker'
import SignUp from './SignUp'
import Login from './Login'
import MobileNavBar from './MobileNavBar'
import checkIsLoggedIn from '../util/checkIsLoggedIn'
import useIsMobile from '../util/useIsMobile.js'
import '../images/ClipNotes_Logo_Secondary_cropped.svg'

function HomeNav(props) {
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const navbarToggleRef = useRef(null)
    const isMobile = useIsMobile()

    useEffect(function () {
        async function updateLoginState() {
            setIsLoggedIn(await checkIsLoggedIn(props.apiClient))
        }
        updateLoginState()

        return () => {}
    }, [])

    const handleLinkClick = () => {
        if (isMobile && navbarToggleRef.current) {
            navbarToggleRef.current.click()
        }
    }

    const loginLink = isLoggedIn ? (
        <div />
    ) : (
        <Nav className="justify-content-end width100">
            <Nav.Link as={Link} to="/login">
                <Button variant="outline-primary" onClick={handleLinkClick}>
                    Log In
                </Button>
            </Nav.Link>
            <Nav.Link as={Link} to="/signup" onClick={handleLinkClick}>
                <Button>Sign Up</Button>
            </Nav.Link>
        </Nav>
    )

    const myVideosLink = isLoggedIn ? (
        <Nav className="mr-auto">
            <Nav.Link as={Link} to="/myvideos" onClick={handleLinkClick}>
                Videos
            </Nav.Link>
            <Nav.Link as={Link} to="/myclipnotes" onClick={handleLinkClick}>
                ClipNotes
            </Nav.Link>
            <Nav.Link as={Link} to="/myclipbooks" onClick={handleLinkClick}>
                Clipbooks
            </Nav.Link>
        </Nav>
    ) : (
        <div />
    )

    return (
        <Container fluid="true" className="homeNavContainer">
            <Helmet>
                <title>ClipNotes | Take Notes on Video</title>
            </Helmet>
            <Router>
                <RouteChangeTracker />
                <Navbar
                    collapseOnSelect
                    expand="md"
                    variant="dark"
                    sticky="top"
                    className="navBar"
                >
                    <Navbar.Brand>
                        <Link to="/myclipnotes" className="textLogo">
                            <img
                                src="../images/ClipNotes_Logo_Secondary_cropped.svg"
                                className="navBarClipNotesLogo"
                            />
                        </Link>
                    </Navbar.Brand>
                    {!isMobile && (
                        <>
                            <Navbar.Toggle
                                aria-controls="responsive-navbar-nav"
                                ref={navbarToggleRef}
                            />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                {myVideosLink}
                                {loginLink}
                            </Navbar.Collapse>
                        </>
                    )}
                </Navbar>
                {isMobile && <MobileNavBar handleLinkClick={handleLinkClick} />}
                <div className="reactRouterDiv">
                    <Switch>
                        <Route exact path="/" component={ComingSoon} />
                        <Route
                            path="/watch/:video_url_id"
                            render={(routeProps) => (
                                <VideoViewer
                                    {...routeProps}
                                    apiClient={props.apiClient}
                                />
                            )}
                        />
                        <Route
                            path="/myvideos"
                            render={(routeProps) => (
                                <MyVideos
                                    {...routeProps}
                                    apiClient={props.apiClient}
                                />
                            )}
                        />
                        <Route path="/signup">
                            <div className="centeringContainer">
                                <SignUp
                                    apiClient={props.apiClient}
                                    isLoggedIn={isLoggedIn}
                                    setIsLoggedIn={setIsLoggedIn}
                                />
                            </div>
                        </Route>
                        <Route path="/login">
                            <div className="centeringContainer">
                                <Login
                                    apiClient={props.apiClient}
                                    isLoggedIn={isLoggedIn}
                                    setIsLoggedIn={setIsLoggedIn}
                                />
                            </div>
                        </Route>
                        <Route
                            path="/myclipnotes"
                            render={(routeProps) => (
                                <MyClipNotes
                                    {...routeProps}
                                    apiClient={props.apiClient}
                                />
                            )}
                        />
                        <Route
                            path="/myclipbooks"
                            render={(routeProps) => (
                                <MyClipbooks
                                    {...routeProps}
                                    apiClient={props.apiClient}
                                />
                            )}
                        />
                        <Route
                            path="/clipbook/:clipbook_id"
                            render={(routeProps) => (
                                <VideoViewer
                                    {...routeProps}
                                    apiClient={props.apiClient}
                                />
                            )}
                        />
                    </Switch>
                </div>
            </Router>
        </Container>
    )
}

HomeNav.propTypes = {
    apiClient: PropTypes.object.isRequired,
}

export default HomeNav
