import ky from 'ky'
import { toast } from 'react-toastify'

export default class ApiClient {
    constructor(apiProxyBaseUrl, token) {
        this.token = token
        this.apiProxyBaseUrl = apiProxyBaseUrl
        this.api = ky.create({
            prefixUrl: apiProxyBaseUrl,
        })
    }

    /**
     * Simple wrapper around the ky instance. In the future, it will handle API
     * mechanics like retries.
     *
     * @param {String} path - the relative path to hit on the API
     * @param {Object} options - options, as documented by ky: https://github.com/sindresorhus/ky#kyinput-options
     *
     * @returns {Object}
     */
    _request(path, options) {
        return this.api(path, options).json()
    }

    get(path, options) {
        return this._request(path, Object.assign({ method: 'get' }, options))
    }

    post(path, payload, options) {
        return this._request(
            path,
            Object.assign({ method: 'post' }, { json: payload }, options)
        )
    }

    patch(path, options) {
        return this._request(path, Object.assign({ method: 'patch' }, options))
    }

    put(path, payload, options) {
        return this._request(
            path,
            Object.assign({ method: 'put' }, { json: payload }, options)
        )
    }

    delete(path, options) {
        return this._request(path, Object.assign({ method: 'delete' }, options))
    }

    /**
     * Checks if the error is a ClipNotes API error and displays a toast.
     * @param {Error} err
     *
     * @return {Boolean} - true if the error was handled, false otherwise
     */
    async displayErrorToast(err) {
        if (err instanceof ky.HTTPError) {
            const apiErrorMessage = (await err.response.json()).error.message
            if (apiErrorMessage) {
                toast.error(apiErrorMessage)
            } else {
                // handles errors from ClipNotes frontend server
                toast.error(err.message)
            }

            return true
        }

        return false
    }
}
