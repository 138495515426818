import React, { useState, useEffect, Fragment } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Modal, Container, Row, Col, Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import Pagination from 'react-responsive-pagination'
import lodashGet from 'lodash/get'
import { intersection } from 'lodash'
import Select from 'react-select'

import VideoSubmissionForm from './VideoSubmissionForm'
import VideoCardDeck from './VideoCardDeck'

function MyVideos(props) {
    const [videoList, setVideoList] = useState(null)
    const [isModalDismissed, setIsModalDismissed] = useState(false)
    const [selectedTags, setSelectedTags] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [searchUrl, setSearchUrl] = useState('')
    const location = useLocation()
    const history = useHistory()

    const [showForm, setShowForm] = useState(false)

    async function refreshVideos() {
        try {
            const videos = await props.apiClient.get('videos')
            const ascendingSortedVideos = [...videos].reverse()
            setVideoList(ascendingSortedVideos)
        } catch (err) {
            if (
                err.response &&
                (err.response.status === 401 || err.response.status === 403)
            ) {
                history.push('/login')
            } else if (!(await props.apiClient.displayErrorToast(err))) {
                throw err
            }
        }
    }

    async function createVideo({ sourceUrl, title, summary, defaultTags }) {
        try {
            const response = await props.apiClient.post('videos', {
                sourceUrl,
                title,
                summary,
                defaultTags,
            })

            await refreshVideos()
            history.push(`/watch/${response.id}`)
        } catch (err) {
            if (!(await props.apiClient.displayErrorToast(err))) {
                throw err
            }
        }
    }

    useEffect(function () {
        refreshVideos()

        if (props.location.state) {
            setSelectedTags(props.location.state.tagHistoryState)
            setCurrentPage(props.location.state.pageHistoryState)
        } else if (props.location.search) {
            const queryString = location.search
            const encodedUrl = queryString.startsWith('?=')
                ? queryString.slice(2)
                : ''
            const decodedUrl = decodeURIComponent(encodedUrl)
            setShowForm(true)
            setSearchUrl(decodedUrl)
        }

        return () => {}
    }, [])

    useEffect(() => {
        if (props.location.state && props.location.state.pageHistoryState) {
            setCurrentPage(props.location.state.pageHistoryState)
        } else {
            setCurrentPage(pageCount)
        }
    }, [videoList, props.location.state])

    if (!videoList) {
        return <div />
    }

    function handleClose() {
        setIsModalDismissed(true)
    }

    function getRelevantVideos(tag = selectedTags) {
        if (tag && tag.length > 0) {
            const selectedTagsArray = tag.map((t) => t.value)
            const selectedTagsFilterFunction =
                selectedTagsArray.length > 0
                    ? (video) =>
                          intersection(video.defaultTags, selectedTagsArray)
                              .length === selectedTagsArray.length
                    : (video) => true
            return videoList.filter(selectedTagsFilterFunction)
        }
        return videoList
    }

    function getUniqueVideoTags() {
        const relevantVideos = getRelevantVideos()

        if (relevantVideos) {
            const tagSet = new Set()

            relevantVideos.forEach((videos) =>
                videos.defaultTags.forEach((tag) => tagSet.add(tag))
            )

            const uniqueVideoTags = Array.from(tagSet).sort((a, b) =>
                a.toLowerCase().localeCompare(b.toLowerCase())
            )

            return uniqueVideoTags.map((tag) => ({ value: tag, label: tag }))
        }
    }

    function updateSelectedTags(tag) {
        setSelectedTags(tag)

        const relevantVideos = getRelevantVideos(tag)
        const pageCount = Math.ceil(relevantVideos.length / perPage)
        const currentPage = Math.min(pageCount, currentPage)

        setCurrentPage(currentPage)

        history.replace({
            location: '/myvideos',
            state: { pageHistoryState: currentPage, tagHistoryState: tag },
        })
    }

    //Pagination code below
    const perPage = 18
    const offset = (currentPage - 1) * perPage
    const videoListPage = getRelevantVideos().slice(offset, offset + perPage)
    const pageCount = Math.ceil(getRelevantVideos().length / perPage)

    function handlePageClick(page) {
        setCurrentPage(page)

        history.replace({
            location: '/myvideos',
            state: {
                pageHistoryState: page,
                tagHistoryState: selectedTags,
            },
        })
    }

    const newSignupModal = (
        <Modal show={!isModalDismissed} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Welcome to ClipNotes!</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>You have successfully joined the beta.</p>
                <p>Add a video to begin!</p>
            </Modal.Body>
        </Modal>
    )

    return (
        <Fragment>
            {lodashGet(location, 'state.isNewSignup') && !isModalDismissed ? (
                newSignupModal
            ) : (
                <div />
            )}
            <div className="myVideos">
                <Helmet>
                    <title>My Videos | ClipNotes</title>
                </Helmet>
                <Container>
                    <Row>
                        <Col>
                            <h2>All Videos</h2>
                        </Col>
                        <Col className="d-flex justify-content-end align-items-center">
                            <Button
                                onClick={() => setShowForm(!showForm)}
                                variant={showForm ? 'danger' : 'success'}
                            >
                                {showForm ? 'Cancel' : 'Add New Video'}
                            </Button>
                        </Col>
                    </Row>
                    {showForm ? (
                        <VideoSubmissionForm
                            submissionCallback={createVideo}
                            searchUrl={searchUrl}
                        />
                    ) : null}
                    <Select
                        isMulti
                        isSearchable
                        className="selectDropdown"
                        defaultValue={selectedTags}
                        onChange={updateSelectedTags}
                        options={getUniqueVideoTags()}
                        placeholder="Search by tags..."
                    />
                    <Pagination
                        total={pageCount}
                        current={currentPage}
                        onPageChange={handlePageClick}
                    />
                    <VideoCardDeck
                        videoList={videoListPage}
                        deleteVideoCallback={refreshVideos}
                        apiClient={props.apiClient}
                    />
                    <Pagination
                        total={pageCount}
                        current={currentPage}
                        onPageChange={handlePageClick}
                    />
                </Container>
            </div>
        </Fragment>
    )
}

MyVideos.propTypes = {
    apiClient: PropTypes.object.isRequired,
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
}

export default MyVideos
