// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-nav-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: #111033;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    padding: 6px 0;
}

.mobile-nav-bar .nav-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #E3F2FF;
    text-decoration: none;
    flex: 1;
    padding: 0 10px;
}

.mobile-nav-bar .nav-link .btn-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #E3F2FF;
    text-decoration: none;
    padding: 0;
}

.mobile-nav-bar .nav-link .btn-link:hover,
.mobile-nav-bar .nav-link .btn-link:focus {
    color: #E3F2FF;
    text-decoration: none;
}

.mobile-nav-bar .nav-link .btn-link svg {
    font-size: 20px;
    margin-bottom: 2px;
}

.mobile-nav-bar .nav-link .btn-link span {
    font-size: 10px;
}`, "",{"version":3,"sources":["webpack://./css/components/MobileNavBar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS;IACT,OAAO;IACP,QAAQ;IACR,WAAW;IACX,yBAAyB;IACzB,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,yCAAyC;IACzC,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;IACd,qBAAqB;IACrB,OAAO;IACP,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;IACd,qBAAqB;IACrB,UAAU;AACd;;AAEA;;IAEI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".mobile-nav-bar {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    z-index: 10;\n    background-color: #111033;\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);\n    padding: 6px 0;\n}\n\n.mobile-nav-bar .nav-link {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    color: #E3F2FF;\n    text-decoration: none;\n    flex: 1;\n    padding: 0 10px;\n}\n\n.mobile-nav-bar .nav-link .btn-link {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    color: #E3F2FF;\n    text-decoration: none;\n    padding: 0;\n}\n\n.mobile-nav-bar .nav-link .btn-link:hover,\n.mobile-nav-bar .nav-link .btn-link:focus {\n    color: #E3F2FF;\n    text-decoration: none;\n}\n\n.mobile-nav-bar .nav-link .btn-link svg {\n    font-size: 20px;\n    margin-bottom: 2px;\n}\n\n.mobile-nav-bar .nav-link .btn-link span {\n    font-size: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
