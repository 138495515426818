const getYoutubeId = require('get-youtube-id')

module.exports = function (url) {
    const youtubeId = getYoutubeId(url)

    return {
        default: `http://img.youtube.com/vi/${youtubeId}/default.jpg`,
        medium: `http://img.youtube.com/vi/${youtubeId}/mqdefault.jpg`,
        high: `http://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`,
    }
}
