import React, { useState, useEffect, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import Pagination from 'react-responsive-pagination'
import { intersection } from 'lodash'
import Select from 'react-select'

import ClipbookCreationForm from './ClipbookCreationForm'
import VideoCardDeck from './VideoCardDeck'

function MyClipbooks(props) {
    const [clipbookList, setClipbookList] = useState(null)
    const [selectedTags, setSelectedTags] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [showForm, setShowForm] = useState(false)

    const history = useHistory()

    async function refreshClipbooks() {
        try {
            const clipbooks = await props.apiClient.get('clipbooks')
            const ascendingSortedClipbooks = [...clipbooks].reverse()
            setClipbookList(ascendingSortedClipbooks)
        } catch (err) {
            if (
                err.response &&
                (err.response.status === 401 || err.response.status === 403)
            ) {
                history.push('/login')
            } else if (!(await props.apiClient.displayErrorToast(err))) {
                throw err
            }
        }
    }

    async function createClipbook({ title, summary, tags }) {
        try {
            await props.apiClient.post('clipbooks', {
                title,
                summary,
                tags,
            })

            await refreshClipbooks()
        } catch (err) {
            if (!(await props.apiClient.displayErrorToast(err))) {
                throw err
            }
        }
    }

    useEffect(function () {
        refreshClipbooks()

        if (props.location.state) {
            setSelectedTags(props.location.state.tagHistoryState)
            setCurrentPage(props.location.state.pageHistoryState)
        }

        return () => {}
    }, [])

    useEffect(() => {
        if (props.location.state && props.location.state.pageHistoryState) {
            setCurrentPage(props.location.state.pageHistoryState)
        } else {
            setCurrentPage(pageCount)
        }
    }, [clipbookList, props.location.state])

    if (!clipbookList) {
        return <div />
    }

    function getRelevantClipbooks(tag = selectedTags) {
        if (tag && tag.length > 0) {
            const selectedTagsArray = tag.map((t) => t.value)
            const selectedTagsFilterFunction =
                selectedTagsArray.length > 0
                    ? (clipbook) =>
                          intersection(clipbook.tags, selectedTagsArray)
                              .length === selectedTagsArray.length
                    : (clipbook) => true
            return clipbookList.filter(selectedTagsFilterFunction)
        }
        return clipbookList
    }

    function getUniqueClipbookTags() {
        const relevantClipbooks = getRelevantClipbooks()

        if (relevantClipbooks) {
            const tagSet = new Set()

            relevantClipbooks.forEach((clipbook) =>
                clipbook.tags.forEach((tag) => tagSet.add(tag))
            )

            const uniqueClipbookTags = Array.from(tagSet).sort((a, b) =>
                a.toLowerCase().localeCompare(b.toLowerCase())
            )

            return uniqueClipbookTags.map((tag) => ({ value: tag, label: tag }))
        }
    }

    function updateSelectedTags(tag) {
        setSelectedTags(tag)

        const relevantClipbooks = getRelevantClipbooks(tag)
        const pageCount = Math.ceil(relevantClipbooks.length / perPage)
        const currentPage = Math.min(pageCount, currentPage)

        setCurrentPage(currentPage)

        history.replace({
            location: '/myclipbooks',
            state: { pageHistoryState: currentPage, tagHistoryState: tag },
        })
    }

    //Pagination code below
    const perPage = 18
    const offset = (currentPage - 1) * perPage
    const clipbookListPage = getRelevantClipbooks().slice(
        offset,
        offset + perPage
    )
    const pageCount = Math.ceil(getRelevantClipbooks().length / perPage)

    function handlePageClick(page) {
        setCurrentPage(page)

        history.replace({
            location: '/myclipbooks',
            state: {
                pageHistoryState: page,
                tagHistoryState: selectedTags,
            },
        })
    }

    return (
        <Fragment>
            <div className="myVideos">
                <Helmet>
                    <title>My Clipbooks | ClipNotes</title>
                </Helmet>
                <Container className="allVideosContainer">
                    <Row>
                        <Col>
                            <h2>All Clipbooks</h2>
                        </Col>
                        <Col className="d-flex justify-content-end align-items-center">
                            <Button
                                onClick={() => setShowForm(!showForm)}
                                variant={showForm ? 'danger' : 'success'}
                                size="md"
                            >
                                {showForm ? 'Cancel' : 'Create New Clipbook'}
                            </Button>
                        </Col>
                    </Row>
                    {showForm ? (
                        <ClipbookCreationForm
                            submissionCallback={createClipbook}
                        />
                    ) : null}
                    <Select
                        isMulti
                        isSearchable
                        className="selectDropdown"
                        defaultValue={selectedTags}
                        onChange={updateSelectedTags}
                        options={getUniqueClipbookTags()}
                        placeholder="Search by tags..."
                    />
                    <hr />
                    <Pagination
                        total={pageCount}
                        current={currentPage}
                        onPageChange={handlePageClick}
                    />
                    <VideoCardDeck
                        videoList={clipbookListPage}
                        deleteVideoCallback={refreshClipbooks}
                        apiClient={props.apiClient}
                    />
                    <Pagination
                        total={pageCount}
                        current={currentPage}
                        onPageChange={handlePageClick}
                    />
                </Container>
            </div>
        </Fragment>
    )
}

MyClipbooks.propTypes = {
    apiClient: PropTypes.object.isRequired,
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
}

export default MyClipbooks
