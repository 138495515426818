import { useState, useEffect } from 'react'

export default function useIsMobile() {
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        const checkIsMobile = () => {
            const mediaQuery = window.matchMedia('(max-width: 768px)')
            setIsMobile(mediaQuery.matches)
        }

        checkIsMobile()

        window.addEventListener('resize', checkIsMobile)

        return () => {
            window.removeEventListener('resize', checkIsMobile)
        }
    }, [])

    return isMobile
}
