import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'

function ClipSpeedControl(props) {
    const handleClick = (e) => {
        const selectedSpeed = parseFloat(
            e.target.innerHTML.substring(0, e.target.innerHTML.length - 1)
        )

        props.selectSpeedCallback(selectedSpeed)
    }

    return [0.25, 0.5, 1, 1.5].map((selectableSpeed) => {
        return (
            <Button
                variant={
                    props.playbackSpeed === selectableSpeed
                        ? 'primary'
                        : 'secondary'
                }
                size="sm"
                key={selectableSpeed}
                onClick={handleClick}
                className="playbackSpeedButton"
            >
                {selectableSpeed}x
            </Button>
        )
    })
}

ClipSpeedControl.propTypes = {
    playbackSpeed: PropTypes.number,
    selectSpeedCallback: PropTypes.func.isRequired,
}

export default ClipSpeedControl
