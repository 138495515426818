import React from 'react'
import HomeNav from './HomeNav'
import ApiClient from '../util/ApiClient'
import PropTypes from 'prop-types'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function BaseApp(props) {
    const apiClient = new ApiClient(props.apiProxyBaseUrl, props.userId)

    return (
        <div className="baseAppDiv">
            <HomeNav apiClient={apiClient} />
            <ToastContainer />
        </div>
    )
}

BaseApp.propTypes = {
    apiProxyBaseUrl: PropTypes.string,
    userId: PropTypes.string,
}

export default BaseApp
