import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Link } from 'react-router-dom'
import { Button, Container, Form, Row } from 'react-bootstrap'
import '../images/ClipNotes_Logo_Main_cropped.svg'

function Login(props) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const updatePassword = (e) => setPassword(e.target.value)

    const updateEmail = (e) => setEmail(e.target.value)

    const handleSubmit = async function () {
        try {
            await props.apiClient.post('users/sessions', {
                authenticationStrategy: {
                    type: 'emailAndPassword',
                    email,
                    password,
                },
            })

            props.setIsLoggedIn(true)
        } catch (err) {
            if (!(await props.apiClient.displayErrorToast(err))) {
                throw err
            }
        }
    }

    const successfulLoginRedirect = props.isLoggedIn ? (
        <Redirect
            to={{
                pathname: '/myvideos',
            }}
        />
    ) : (
        <div />
    )

    return (
        <Container fluid className="centeredContainer">
            {successfulLoginRedirect}
            <img
                src="../images/ClipNotes_Logo_Main_cropped.svg"
                className="loginSignUpLogo"
            />
            <div className="formArea">
                <Form className="loginSignUpForm">
                    <Form.Group as={Row} controlId="emailField">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            required
                            type="email"
                            onChange={updateEmail}
                        />
                    </Form.Group>
                    <Form.Group as={Row} controlId="passwordField">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            required
                            type="password"
                            onChange={updatePassword}
                        />
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Button variant="success" onClick={handleSubmit} block>
                            Login
                        </Button>
                    </Form.Group>
                </Form>
                <div style={{ textAlign: 'center' }}>
                    <p>Don’t have an account?</p>
                    <p>
                        <Link to="/signup">Sign Up</Link>
                    </p>
                </div>
            </div>
        </Container>
    )
}

Login.propTypes = {
    apiClient: PropTypes.object.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    setIsLoggedIn: PropTypes.func.isRequired,
}

export default Login
