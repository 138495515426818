import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'

function AutoPlayControl(props) {
    const handleClick = (e) => {
        props.toggleCallback()
    }

    return (
        <Button
            variant={props.isAutoPlay ? 'primary' : 'secondary'}
            size="sm"
            onClick={handleClick}
        >
            {props.isAutoPlay ? 'ON' : 'OFF'}
        </Button>
    )
}

AutoPlayControl.propTypes = {
    isAutoPlay: PropTypes.bool,
    toggleCallback: PropTypes.func.isRequired,
}

export default AutoPlayControl
