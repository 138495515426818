import ReactDOM from 'react-dom'
import React from 'react'
import ReactGA from 'react-ga'
import BaseApp from './components/BaseApp'
import './fonts/arkibal-display-medium.otf'

const wrapper = document.getElementById('container')

ReactGA.initialize(window.__CLIPNOTES__.trackingId)

wrapper
    ? ReactDOM.render(
          <BaseApp
              apiProxyBaseUrl={window.__CLIPNOTES__.apiProxyBaseUrl}
              userId={window.__CLIPNOTES__.userId}
          />,
          wrapper
      )
    : alert('DOM element not found')
