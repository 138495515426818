import React from 'react'
import { Link } from 'react-router-dom'
import { Nav, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { FiVideo, FiFileText, FiBook } from 'react-icons/fi'
import '../css/components/MobileNavBar.css'

function MobileNavBar({ handleLinkClick }) {
    return (
        <Nav className="mobile-nav-bar" sticky="bottom">
            <Nav.Link as={Link} to="/myvideos" onClick={handleLinkClick}>
                <Button variant="link">
                    <FiVideo />
                    <span>Videos</span>
                </Button>
            </Nav.Link>
            <Nav.Link as={Link} to="/myclipnotes" onClick={handleLinkClick}>
                <Button variant="link">
                    <FiFileText />
                    <span>ClipNotes</span>
                </Button>
            </Nav.Link>
            <Nav.Link as={Link} to="/myclipbooks" onClick={handleLinkClick}>
                <Button variant="link">
                    <FiBook />
                    <span>Clipbooks</span>
                </Button>
            </Nav.Link>
        </Nav>
    )
}

MobileNavBar.propTypes = {
    handleLinkClick: PropTypes.func,
}

export default MobileNavBar
